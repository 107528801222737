<template>

 <header>

   <v-app-bar
       color="primary"
       density="comfortable"
   >
     <v-container>
      <v-app-bar-title class="app-title">Canal de denuncias</v-app-bar-title>
     </v-container>
   </v-app-bar>

  </header>

</template>

<style scoped>
.app-title {
  color: white;
}
</style>
<script setup>
</script>