import store from '@/store/index';
import * as api from '@/config/apiEndpoints';

class AuthService {

    constructor (axios, router) {
        this.axios = axios;
        this.router= router;
    }

    async login(mail, password) {

        const response = await this.axios.post( api.LOGIN_ENDPOINT, { payload : { mail, password } });
        console.log(response.data.user);
        if (response && !response.data.user.active2fa){

            await store.dispatch('authenticateUser', true);
            await store.dispatch('saveToken', response.data.user.token);

            return true;

        } else if (response && response.data.user.active2fa && !response.data.user.passed2fa){

            await store.dispatch('setTwoAuthSmsStatus', true);
            this.router.push({path: `/admin/2fa/${response.data.user.smsid}`, query: { telephone: response.data.user.telephone}});
        }
        else if (response && response.data.user.active2fa && response.data.user.passed2fa){
            await store.dispatch('authenticateUser', true);
            await store.dispatch('saveToken', response.data.user.token);
            this.router.push('/admin/dashboard');
            return true;
        }
        else {
            await store.dispatch('setTwoAuthSmsStatus', false);
            await store.dispatch('authenticateUser', false);

            return false;
        }
    }

    async twoAuthVerification (code, smsid) {

        const response = await this.axios.post( api.TWOAUTH_ENDPOINT, { payload: { code, smsid } } );

        if (response && response.data.user.token) {

            localStorage.setItem('token', response.data.user.token);
            await store.dispatch('authenticateUser', true);
            await store.dispatch('saveToken', response.data.user.token);
            this.router.push('/admin/dashboard');
            return true;
        }
        else {

            await store.dispatch('authenticateUser', false);

            return false;
        }
    }

    getToken() {
        return store.getters.getToken;
    }

    async forceLogout() {
        this.router.push({name: 'login'});
        return store.dispatch('forceLogout');
    }

}

export default AuthService;
