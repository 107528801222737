import { showToast } from "@/helpers/ShowToast";
import { HttpStatusCode } from "axios";
import { errorMessages } from "@/config/messages";


class ApiErrorService {

    constructor(authService, router, error) {
        this.auth = authService;
        this.router = router;
        this.error = error;
    }
    async onResponseRejected(error) {
        switch (error?.response?.status) {
            case (HttpStatusCode.Unauthorized):
                this.handleUnauthorizedError();
                break;
            case (HttpStatusCode.Forbidden): //Unauthorized in Axios
                this.handleForbiddenError(error);
                break;
            case (HttpStatusCode.BadGateway):
                this.handleNetworkError();
                break;
            default:
                this.handleUnknownError();
        }
        return false;
    }

    handleUnauthorizedError(){
        showToast(errorMessages.UNAUTHORIZED_ERROR, 'warning');
    }
    handleNetworkError() {
        showToast('Connection error, retry after a few seconds', 'danger');
    }
    handleUnknownError(){
        showToast(errorMessages.UNEXPECTED_ERROR, 'danger');
    }

    handleForbiddenError(error) {
        showToast(error.response.data.message);
    }
}

export default ApiErrorService;