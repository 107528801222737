
export const loginForm = {
    invalidEmail: 'El email introducido no es válido',
    emailLabel: 'E-mail',
    emailErrorMsg: 'Debe introducir una dirección de correo válida',
    passwordLabel : 'Contraseña',
    requiredInput: 'El campo no puede estar vacío',
    rememberPasswordLabel: "Recordar Contraseña",
    loginFormButtonSend: 'Enviar'
};

export const OTP = {
    title: "Verifica tu identidad",
    mainText: 'Hemos enviado un SMS a este teléfono:',
    secondaryText: 'Por favor, introduce el código a continuación:',
    caption: "¿No has recibido el mensaje?",
    resendCode: "Reenviar",
    buttonText: "Verificar"
};

export const errorMessages = {
    UNEXPECTED_ERROR : 'Ha ocurrido un error inesperado!',
    UNAUTHORIZED_ERROR : 'Credenciales incorrectas',
};

export const complaintRegister = {
    disclaimerText: 'En conformidad con las disposiciones de la Directiva Whistleblowing, nos comprometemos a ofrecer la máxima protección y confidencialidad de sus datos personales. Esta práctica es esencial para garantizar una protección integral a cada denunciante. Nos obligamos a mantener en estricto secreto toda la información personal que nos proporcione, siempre y cuando así lo desee explícitamente.\n' +
        'Nos aseguramos de que su identidad y los detalles de su denuncia sean tratados con la mayor discreción y seguridad. Implementando un sistema de doble factor de autenticación, reforzamos esta garantía, asegurando que únicamente el personal autorizado y usted mismo puedan acceder a la información de su denuncia. En nuestra organización, la seguridad de su información y la protección de su privacidad son nuestras máximas prioridades.',

};

export const FAQ = [
    {
        value: '1',
        title: '¿Qué es un Canal de Denuncias?',
        text: `Un canal de denuncia es un componente crucial en la estructura de comunicación interna y externa de una empresa, diseñado para prevenir y gestionar comportamientos irregulares. Este canal está abierto no solo a empleados y colaboradores, sino también a cualquier persona, incluso aquellas ajenas a la organización, permitiéndoles reportar una amplia gama de incidencias. Estas pueden incluir desde el acoso laboral hasta el blanqueo de capitales, así como violaciones del código ético o de los procedimientos establecidos de la empresa. <br><br>
            La confidencialidad es el principio fundamental que respalda el canal de denuncia, alineándose con los principios de la Ley de Protección de Datos. El acceso a la información reportada está restringido al "Compliance Counter", responsable de la supervisión y el seguimiento de las denuncias.`
    },
    {
        value: '2',
        title: '¿Qué tipo de denuncias puedo presentar en este canal?',
        text: `Este canal está específicamente diseñado para facilitar la presentación de denuncias relacionadas con una amplia gama de irregularidades en el ámbito empresarial y organizacional. Usted puede reportar situaciones que incluyan, pero no se limiten a, violaciones de la intimidad personal, problemas de seguridad, irregularidades en la contratación pública, y otras prácticas que considere inapropiadas o contrarias a las políticas internas de la empresa o la legislación vigente.<br>
               Sin embargo, es importante señalar que este canal no acepta denuncias relacionadas con asuntos de carácter sexual, terrorismo, o delitos de naturaleza similar. Nuestro compromiso es proporcionar un medio seguro y confidencial para reportar preocupaciones específicas dentro del ámbito empresarial y organizacional, asegurando que cada caso sea tratado con la seriedad y atención adecuadas.`
    },
    {
        value: '3',
        title: '¿Puedo preservar mi identidad al realizar una denuncia?',
        text: `Por supuesto, usted tiene la opción de mantener su anonimato al presentar una denuncia. Las denuncias pueden realizarse de manera transparente, proporcionando sus datos de contacto, o de forma anónima, utilizando un alias.<br><br> Queremos asegurarle que cualquier información personal, como su número de teléfono móvil o dirección de correo electrónico, que nos proporcione será tratada con la máxima confidencialidad. Estos datos no serán revelados a la empresa receptora de su denuncia y se utilizarán exclusivamente para la gestión interna de su caso a través de nuestro portal, incluyendo la verificación de su identidad mediante un sistema de autenticación de doble factor.`
    },
    {
        value: '4',
        title: '¿Qué procedimiento siguen las denuncias?',
        text: `<div> 
                <span>Este portal sigue rigurosamente el procedimiento establecido por la Directiva Whistleblowing, también conocida como la directiva de protección de denunciantes de corrupción. 
                <br/>Este procedimiento se estructura de la siguiente manera para garantizar un trámite eficaz y seguro de las denuncias internas:
                </span>
                <br/>
                <br/>
                <ol>
                    <li><strong>Canales de Comunicación Seguros y Confidenciales:</strong> Implementamos canales de comunicación diseñados bajo estrictos principios de confidencialidad y seguridad. Esto es esencial para proteger la identidad de los denunciantes. La comunicación en nuestro servidor esta cifrada usando protocolos seguros de trasmisión de datos, así como la obligatoriedad del doble factor para poder acceder a la información.</li>
                    <li><strong>Designación de Responsables de la Gestión de Denuncias:</strong> Cada empresa debe de asignar a una persona o departamento específico la responsabilidad de recibir, hacer seguimiento y acusar recibo de las denuncias. Este responsable mantiene la comunicación con el denunciante, solicita información adicional si es necesario y asegura un seguimiento adecuado de cada caso</li>
                    <li><strong>Plazo de Respuesta:</strong> El sistema controla y realiza seguimiento para agilizar una respuesta a las denuncias en un plazo no mayor a 90 días tras el acuse de recibo, asegurando así un proceso ágil y eficiente.</li>
                    <li><strong>Procedimiento Claro y Transparente:</strong> Nuestro procedimiento de denuncia incluye la provisión de información clara y directa que pueda ser trasladado a las autoridades competentes en el caso de ser necesario, garantizando que todas las denuncias se manejen con la mayor seriedad y conforme a la normativa vigente.</li>
                </ol>
                </div>`
    },
];