import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import {showToast} from "@/helpers/ShowToast";
import {errorMessages} from "@/config/messages";

const routes =  [
    {
        path: "/",
        name: `Canal de denuncias`,
        component: () => import("@/pages/IndexView.vue")
    },
    {
        path: "/registrar-denuncia",
        name: "registrar denuncia",
        title: 'Registrar',
        meta: {
            requiresAuth: false,
            title: 'Registrar denuncia'
        },
        alias: "/registrar",
        component: () => import("@/pages/complaint/CompliantRegister.vue")
    },
    {
        path: '/2fa/:hash',
        name: "2fa",
        meta: {
            requiresAuth: false,
            title: 'Verifica tu identidad'
        },
        beforeEnter: () => {
            if (store.getters['isWaitingTwoAuth']){
                return true;
            }else {
                return '/';
            }
        },
        props: route => ({ phone: route.query.telephone }),
        component: () => import("@/pages/OTPView.vue")
    },
    {
        path: '/manage/:complaintID',
        name: 'Gestionar denuncia',
        meta: {
            requiresAuth: true,
            title: 'Gestionar denuncia | Canal denuncias'
        },
        alias: '/manage/:complaintID',
        component: () => import("@/pages/ComplaintManagement.vue")
    },
    {
        path: '/admin',
        name: 'manager zone',
        children: [
            {
                path: "login",
                name: "login",
                meta: {
                    requiresAuth: false,
                    title: 'Iniciar sesión | Canal denuncias'
                },
                component: () => import("@/pages/admin/AdminLoginView.vue")
            },
            {
                path: '2fa/:hash',
                name: "2fa",
                meta: {
                    requiresAuth: false,
                    title: 'Verifica tu identidad'
                },
                beforeEnter: async () => {
                    if (store.getters['isWaitingTwoAuth']){
                        await store.dispatch('setUserAsAdmin', true);
                        return true;
                    }else {
                        return '/';
                    }
                },
                props: route => ({ phone: route.query.telephone }),
                component: () => import("@/pages/OTPView.vue")
            },
            {
                path: 'dashboard',
                name: 'Panel de administración',
                meta: {
                    requiresAuth: false,
                    title: 'Panel de administración | Canal denuncias',
                },
                alias: '/dashboard',
                component: () => import("@/pages/admin/AdminDashboard.vue")
            },
            {
                path: 'manage/:complaintID',
                name: 'Gestionar denuncia',
                meta: {
                    requiresAuth: false,
                    title: 'Gestionar denuncia | Canal denuncias'
                },
                alias: '/manage/:complaintID',
                component: () => import("@/pages/ComplaintManagement.vue")
            },
            {
                path: "remember-password",
                name: "Recordar contraseña | Canal de denuncias",
                alias: '/remember',
                meta: {
                    requiresAuth: false,
                    title: 'Recordar contraseña'
                },
                component: () => import("@/pages/admin/RememberPasswordView.vue")
            }
        ]
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

/**
 * Required for set meta title from the router
 */
router.beforeEach((to, from, next) => {
    const title = to.meta.title || 'Portal denuncias';

    if (title) {
        document.title = title;
    }
    next();
});

/**
 * Required for redirect access from protected urls with isLoggedIn = false to login
 */
router.beforeEach(async (to) => {

    if (!store.getters["isLoggedIn"] && to.meta.requiresAuth && to.name !== 'login' && to.name !== '2fa') {
        return { name: 'login' };
    }

});

router.onError(() => {
    showToast(errorMessages.UNEXPECTED_ERROR, 'danger');
});
export default router;