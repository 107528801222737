import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import AuthService from "@/auth/AuthService";
import ApiErrorService from "@/services/ApiErrorService";
import permissionDirective from '@/directives/permissionDirective';
import * as api from '@/config/apiEndpoints';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'mosha-vue-toastify/dist/style.css';
import '@/assets/custom.css';

// Vuetify
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { mdiAccount, mdiIncognito, mdiPaperclip, mdiChatOutline, mdiSend } from '@mdi/js';
import 'vuetify/styles';
import LuxonAdapter from '@date-io/luxon';

const luxon = new LuxonAdapter({ locale: 'es'});

const myCustomTheme = {
    dark: false,
    colors: {
        primary: '#579C33',

    }
};

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
            account: mdiAccount,
            anonymous: mdiIncognito,
            attachment: mdiPaperclip,
            message: mdiChatOutline,
            send: mdiSend
        },
        sets: {
            mdi,
        },
    },
    lang: {
        locale: 'es',
    },
    components,
    directives,
    date: {
        adapter: luxon
    },
    theme: {
        defaultTheme: 'myCustomTheme',
        themes: {
            myCustomTheme
        }
    },
    defaults: {
        VCard: {
            class: 'custom-shadow',
            rounded: '0',
        },
        VBtn: {
            variant: 'tonal',
            rounded: '0',
            class: 'custom-button'
        },
        VInput: {
            class: 'custom-input'
        },
        VApp: {
            class: 'background-grey'
        },
        VTextField: {
            rounded: '0'
        },
        VTextarea: {
            variant: 'solo',
            rounded: '0'
        }
    }
});

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    headers: {
        "Content-type": "application/json",
    }
});
/** Auth module
*
* @type {AuthService}
*/
const auth = new AuthService(axiosInstance, router);

/** Axios global error handler service instance
 *
 * @type {ApiErrorService}
 */
const httpErrorService = new ApiErrorService(auth, router);

/**
 * Interceptor for auth token
 */
axiosInstance.interceptors.request.use((config) => {

    if(config.url !== api.LOGIN_ENDPOINT || config.url !== api.TWOAUTH_ENDPOINT){
        {
            config.headers['Token'] = `${auth.getToken()}` ;
        }
    }
    config.headers.set('apikey', process.env.VUE_APP_API_KEY);

    return config;

}, (error) => {
    console.error(error); // TODO delete this console.log
    httpErrorService.onResponseRejected(error);
});

/**
 * Interceptor for error handling using ApiErrorService
 */
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, (error) => {
    httpErrorService.onResponseRejected(error).then(function(refresh) {
        if (refresh) {
            axiosInstance.request(error.config);
        }
    });
});


const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);
app.directive('permission', permissionDirective);
app.config.globalProperties.$axios = { ...axiosInstance };

app.mount('#app');