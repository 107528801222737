<template>
    <v-app>
        <header-component></header-component>
        <v-container class="h-100">
            <router-view />
        </v-container>
    </v-app>
</template>

<script>
import {hideToast, showToast} from "@/helpers/ShowToast";


import HeaderComponent from "@/components/common/HeaderComponent.vue";
export default {
    name: 'App',
  components: {HeaderComponent},
    data() {
        return {

            isOnline: navigator.onLine
        };
    },
    methods: {
        updateOnlineStatus(event){
            this.isOnline = event.type === 'online';
        },
    },
    watch: {
      isOnline(online){
        if(!online){
          showToast('Your internet connection is lost', 'danger', -1); // timeout -1 for prevent auto-hide
        }
        else if (online){
          hideToast();
        }
      }
    },
    async mounted() {
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
    },
    beforeUnmount() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
    }
};
</script>
<style>


</style>