import store from "@/store";

function extractPermissions(user) {
    let permissions = [];
    if (!user) {
        return permissions;
    }

    permissions = permissions.concat(user.permissions.map(permission => permission.name));
    user.groups.forEach(group => {
        group.roles.forEach(role => {
            permissions = permissions.concat(role.permissions.map(permission => permission.name));
        });
    });
    user.roles.forEach(role => {
        permissions = permissions.concat(role.permissions.map(permission => permission.name));
    });

    return [...new Set(permissions)];
}

export function havePermission(permissionRequired) {
    if (!permissionRequired) {
        return true;
    }

    const user = store.getters["auth/user"];
    const permissions = extractPermissions(user);
    return permissions.includes(permissionRequired);
}