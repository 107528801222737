import {havePermission} from "@/helpers/PermissionHelper";

function checkPermission(el, binding) {
    if (!havePermission(binding.value)) {
        el.style.display = 'none';
    }
}

export default {
    mounted(el, binding) {
        checkPermission(el, binding);
    },
    updated(el, binding) {
        checkPermission(el, binding);
    },
};