import { createStore, createLogger } from 'vuex';


const debug = process.env.NODE_ENV !== 'production';

export default createStore({
    state: {
        isAuthenticated: false,
        isAdmin: false,
        token: null,
        user: {
            id: "77463bd3",
            anonymous: false,
            name: 'Francisco Martínez',
        },
        twoAuthSmsSend: false
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setToken(state, token) {
            localStorage.setItem('pref', token);
            state.token = token;
        },
        setLogout(state) {
            state.token = null;
            state.isAuthenticated = false;
            state.twoAuthSmsSend = false;
        },
        setTwoAuthSmsSend(state, status) {
          state.twoAuthSmsSend = status;
        },
        setAutenticatedUser(state, user) {
            state.user = user;
        },
        setIsAdmin( state, isAdmin) {
            state.isAdmin = isAdmin;
        }
    },
    actions: {
        authenticateUser({ commit }, status) {
            commit('setAuthentication', status);
        },
        saveToken({ commit }, token) {
            commit('setToken', token);
        },
        forceLogout({ commit }) {
            commit('setLogout');
        },
        setTwoAuthSmsStatus({ commit }, status) {
            commit('setTwoAuthSmsSend', status);
        },
        setUser( { commit }, user){
            commit('setLoggedUser', user);
        },
        setUserAsAdmin( { commit }, isAdmin) {
            commit('setIsAdmin', isAdmin);
        }
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        isLoggedIn(state) {
            return state.isAuthenticated;
        },
        isWaitingTwoAuth(state) {
            return state.twoAuthSmsSend;
        },
        getUser(state) {
            return state.user;
        },
        getIsAdmin(state) {
            return state.isAdmin;
        }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});